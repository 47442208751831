import $ from 'jquery';
import SwiperCore, { Navigation, EffectFade, Thumbs } from 'swiper/core';
import Swiper from 'swiper';
import { Breakpoint } from '../../../types/breakpoint';
import LazyImages from '../../../components/lazy-images';
export default class MileStoneSlider {
    static $slider = $('.js__slider-milestone');
    static $thumbsSlider = $('.js__slider-milestone-line');
    static slider;
    static thumbsSlider;
    static thumbsOptions = {
        spaceBetween: 40,
        slidesPerView: 6,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        breakpoints: {
            [Breakpoint.md]: {
                spaceBetween: 50,
                slidesPerView: 'auto',
            },
        },
    };
    static options = {
        spaceBetween: 10,
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    };
    static init() {
        if (!this.$slider.length || !this.$thumbsSlider.length) {
            return;
        }
        SwiperCore.use([Navigation, EffectFade, Thumbs]);
        this.thumbsSlider = new Swiper(this.$thumbsSlider.get(0), this.thumbsOptions);
        this.slider = new Swiper(this.$slider.get(0), {
            ...this.options,
            thumbs: {
                swiper: this.thumbsSlider,
            },
        });
        $('.milestone-img[data-index=0]').clone().appendTo('.js__slider-milestone-images');
        LazyImages.update();
        this.setImageForActiveSlider();
        this.slider.on('slideChange', () => this.setImageForActiveSlider());
    }
    static setImageForActiveSlider() {
        $('.milestone-img').removeClass('visible');
        $(`.milestone-img[data-index=${this.slider.realIndex}]:first`).addClass('visible');
    }
}
