import { __decorate } from "tslib";
import $ from 'jquery';
import SwiperCore, { Navigation } from 'swiper/core';
import Swiper from 'swiper';
import { throttle } from 'helpful-decorators';
export default class NewsSlider {
    static swiper;
    static $slider = $('.js__news-slider');
    static $sliderContainer = $('.js__news-slider-container');
    static options = {
        slidesPerView: 'auto',
        resizeObserver: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    };
    static init() {
        if (!this.$slider.length) {
            return;
        }
        SwiperCore.use([Navigation]);
        const sliderOffsetPadding = this.calculateSliderPadding();
        this.swiper = new Swiper(this.$slider.get(0), {
            ...this.options,
            slidesOffsetBefore: sliderOffsetPadding,
            slidesOffsetAfter: sliderOffsetPadding,
        });
        $(window).on('resize', this.handleResize.bind(this));
    }
    static calculateSliderPadding() {
        return (this.$sliderContainer.outerWidth(true) - this.$sliderContainer.width()) / 2;
    }
    static handleResize() {
        const sliderOffsetPadding = this.calculateSliderPadding();
        this.swiper.params.slidesOffsetBefore = sliderOffsetPadding;
        this.swiper.params.slidesOffsetAfter = sliderOffsetPadding;
        this.swiper.update();
    }
}
__decorate([
    throttle(300)
], NewsSlider, "handleResize", null);
